<template>
     <div class="note_bid_con">
          <div class="note_bid">

               <div class="bid_con">
                    <div class="note_title">{{ title }}</div>

                    <div style="margin-top: 31px;">
                         <div class="outer2 flex-row" v-for="(val, key, i) in data" :key="i">
                              <div class="flex_row_start_center bd3">
                                   <div class="flex_row_end_center bd31">
                                        <span class="note_info2">{{ alias[key]}}：</span>
                                   </div>
                                   <div class="rich_text" v-if="key == 'content'">
                                        <div v-html="val"></div>
                                   </div>
                                   <div class="c959" v-else>{{ val }}</div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted, toRefs, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {

     setup() {
          const data = ref({})
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const alias = {
               bidTitle: L['招标主题'],
               bidCode: L['招标编号'],
               content: L['公告内容'],
          }
          const router = useRouter()
          const route = useRoute()
          const title = ref('')
          const getBidDetail = () => {
               proxy.$get('v3/business/front/bid/endDetail', {
                    bidId: route.query.bidId,
                    endId: route.query.endId
               }).then(res => {
                    title.value = res.data.title
                    Object.keys(alias).forEach(item => {
                         if (item == 'content') {
                              data.value[item] = proxy.$quillEscapeToHtml(res.data[item])
                         } else {
                              data.value[item] = res.data[item]
                         }
                    });

               })
          }


          onMounted(() => {
               getBidDetail()
          })

          return {
               data,
               alias,
               title,
               L
          }
     }
}
</script>


<style lang="scss">
.validate {
     .vBorder {
          border-color: #F30606 !important;
     }

     .error_msg {
          display: block;
          margin-top: 2px;
     }
}

.error_msg {
     font-size: 12px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #F30606;
     display: none;
}

.section9 {
     .el-input__inner {
          border: none;
          height: 27px;
          width: 318px;
     }
}

#upload {
     display: none;
}

.section2g {
     .el-input__inner {
          border: none;
          height: 30px;
          width: 220px;
     }

     .el-input {
          height: 27px;
          line-height: 30px;
     }

     .el-input__icon {
          height: 27px;
          line-height: 30px;
     }
}

/*** 消除input元素 type="number" 时默认的 加减按钮*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
}

/*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
input[type="number"] {
     -moz-appearance: textfield;
}

input {
     border: none;
     outline: none;
     height: 32px;
}


.note_bid_con {
     background: $colorJ;
     padding: 1px;

     .note_bid {
          width: 1200px;
          background: #FFFFFF;
          margin: 0 auto;
          margin-top: 20px;
          margin-bottom: 20px;

          .bid_con {
               padding: 20px;

          }

          .note_title {
               font-size: 24px;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #333333;
               text-align: center;
               margin-top: 21px;
          }

          .outer2 {
               width: 100%;

               .bd3 {
                    margin-top: 24px;

                    .bd31 {
                         /* margin-top: 10px; */
                         margin-right: 12px;
                         min-width: 113px;
                         width: 113px;
                         text-align: right;

                    }

                    .word2 {
                         color: rgba(240, 2, 2, 1);
                         font-size: 14px;
                         line-height: 14px;
                         margin-right: 4px;
                    }

                    .note_info2 {
                         color: #95989C;
                         font-size: 14px;
                         line-height: 14px;
                         white-space: nowrap;
                    }

                    .outer15 {
                         border-radius: 4px;
                         height: 35px;
                         border: 1px dashed rgba(40, 95, 222, 1);
                         width: 150px;
                         cursor: pointer;

                         .info10 {
                              height: 14px;
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 14px;
                              display: block;
                              line-height: 35px;
                              text-align: center;
                         }
                    }
               }

               .bd4_1 {
                    background: #F7F8FA;
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    padding: 0 10px;
                    border-radius: 3px;
                    width: 1030px;

                    &.w320 {
                         width: 320px !important;
                    }

                    input {
                         flex: 1;
                         background: #F7F8FA;
                    }

                    .word3 {
                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                    }
               }


               .section9 {
                    width: 320px;
                    height: 32px;
                    background: #F7F8FA;
                    border: 1px solid #C9C9C9;
                    border-radius: 3px;

                    input {
                         background: #F7F8FA;
                    }
               }


               .section2g {
                    background-color: rgba(255, 255, 255, 1);
                    border: 1px solid #C9C9C9;
                    border-radius: 3px;

                    .section21g {
                         border: 1px solid #fff;
                         height: 32px;
                    }
               }


               .set_bid_end {
                    margin-left: 20px;
               }



               .op_item {
                    margin-left: 10px;
                    width: 100px;
                    height: 36px;
                    opacity: 1;
                    border-radius: 18px;
                    text-align: center;
                    line-height: 36px;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    color: #333333;
                    cursor: pointer;

                    &.chosen {

                         background: #6590F3;
                         color: #FFFFFF;

                    }
               }
          }

          .c959 {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: $colorTitle1;
          }


          .box2g {
               margin-left: 23px;

               img {
                    width: 18px;
                    height: 18px;
               }

               .box3 {
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(201, 201, 201, 1);
               }

               .info11 {

                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;

                    margin: 0px 0 0 8px;
               }
          }

          .outer14 {
               width: 618px;
               height: 35px;
               margin: 30px 0 0 79px;

               .txt3 {
                    height: 15px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 15px;
                    margin-top: 10px;
                    display: block;
                    margin-right: 4px
               }

               .disabledUpload {
                    position: relative;

                    &:after {
                         content: '';
                         position: absolute;
                         top: 0;
                         left: 0;
                         right: 0;
                         bottom: 0;
                         opacity: 0.3;
                    }



                    .outer15 {
                         border: 1px dashed #999;
                         color: #999;

                         .info10 {
                              color: #999;
                         }
                    }
               }

               .outer15 {
                    border-radius: 4px;
                    height: 35px;
                    border: 1px dashed rgba(40, 95, 222, 1);
                    width: 150px;
                    cursor: pointer;

                    .info10 {
                         width: 70px;
                         height: 14px;
                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 10px 0 0 40px;
                    }
               }

               .word15 {
                    height: 14px;
                    color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    margin-top: 11px;
                    display: block;
                    margin-left: 30px;
               }
          }

          .table_bd {
               border: 1px solid #E7E7E7;
               border-bottom: none;

               .has-gutter tr th {
                    background: #F2F2F2;
               }
          }

          .box11 {
               box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
               background-color: rgba(255, 255, 255, 1);
               height: 79px;
               margin-top: 18px;

               .box12g {
                    width: 120px;
                    height: 40px;
                    background: $colorMain;
                    border-radius: 3px;
                    cursor: pointer;
                    text-align: center;
                    line-height: 40px;

                    .txt17 {
                         color: rgba(255, 255, 255, 1);
                         font-size: 16px;
                         white-space: nowrap;
                         display: block;
                    }
               }


               .box12f {
                    width: 120px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #BFBFBF;
                    border-radius: 3px;
                    cursor: pointer;
                    text-align: center;
                    line-height: 40px;
                    margin-right: 50px;

                    .txt17 {

                         color: #333333;
                         font-size: 16px;
                         white-space: nowrap;
                         display: block;
                    }
               }
          }
     }

     .con1 {
          margin-bottom: 10px;

          .icon2 {
               width: 30px;
               height: 30px;
               margin-left: 9px;
          }

          .word9 {
               color: rgba(51, 51, 51, 1);
               font-size: 12px;
               margin: 0px 0 0 16px;
          }

          .info7 {
               color: rgba(40, 95, 222, 1);
               font-size: 12px;
               margin: 0px 0 0 24px;
               cursor: pointer;
          }
     }
}
</style>